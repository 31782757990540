<!-- eslint-disable max-len -->
<template>
  <div class="unsupported w-full h-full">
    <div class="h-full text-center main-content mx-auto pt-24">
      <img :src="$constants.images.logo.svg.black" class="w-48 inline-block" />
      <div class="h3">Unsupported Browser</div>
      <p class="italic text-grey-600">
        Sorry, but Londolozi.com no longer supports the Internet Explorer
        browser.
      </p>
      <p class="italic text-grey-600">
        Please use a modern browser to access our site:
      </p>
      <div class="browsers pt-6">
        <a href="https://www.mozilla.org/en-US/firefox/new/" title="Firefox">
          <img
            src="https://cdn.londolozi.com/wp-content/uploads/2020/10/firefox_128x128.png"
          />
        </a>
        <a href="https://www.google.com/chrome/" title="Chrome">
          <img
            src="https://cdn.londolozi.com/wp-content/uploads/2020/10/chrome_128x128.png"
          />
        </a>
        <a href="https://brave.com/download/" title="Brave">
          <img
            src="https://cdn.londolozi.com/wp-content/uploads/2020/10/brave_128x128.png"
          />
        </a>
        <a href="https://www.microsoft.com/en-us/edge" title="Edge">
          <img
            src="https://cdn.londolozi.com/wp-content/uploads/2020/10/edge_128x128.png"
          />
        </a>
        <a href="https://support.apple.com/downloads/safari" title="Safari">
          <img
            src="https://cdn.londolozi.com/wp-content/uploads/2020/10/safari_128x128.png"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LondoUnsupported",
};
</script>

<style scoped lang="scss">
.unsupported {
  .main-content {
    width: 600px;
    .browsers {
      a {
        @apply pr-5;
        float: left;
        img {
          width: 100px;
        }
      }
    }
  }
}
</style>
