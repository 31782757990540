<template>
  <div class="loading-box" v-show="initialLoaderRemoved">
    <div class="sk-cube-grid">
      <div class="sk-cube sk-cube1"></div>
      <div class="sk-cube sk-cube2"></div>
      <div class="sk-cube sk-cube3"></div>
      <div class="sk-cube sk-cube4"></div>
      <div class="sk-cube sk-cube5"></div>
      <div class="sk-cube sk-cube6"></div>
      <div class="sk-cube sk-cube7"></div>
      <div class="sk-cube sk-cube8"></div>
      <div class="sk-cube sk-cube9"></div>
      <div style="clear: both">&nbsp;</div>
    </div>

    <div class="loading-text">
      Please standby as the Londolozi website loads.<br />This should take a few
      seconds.
      <br />
      <span ref="factsPlaceholder">&nbsp;</span>
    </div>
  </div>
</template>

<script>
const factsArray = [
  "Ostriches can run faster than horses, and the males can roar like lions",
  "The female lion does ninety percent of the hunting",
  "The bat is the only mammal that can fly",
  "For every human in the world there are one million ants",
  "A group of owls is called a parliament",
  "The honey bee has been around for 30 million years",
  "Hippos can run faster than humans",
  "A woodpecker can peck 20 times per second",
  "Fireflies do not bite or have pincers. Fireflies are harmless, they don't even carry diseases",
];

export default {
  name: "LondoLoadingIndiciator",
  data() {
    return {
      initialLoaderRemoved: false,
    };
  },
  mounted() {
    const firstLoaderToDelete = document.getElementById("first-load-loader");

    if (!firstLoaderToDelete) {
      const randomElement =
        factsArray[Math.floor(Math.random() * factsArray.length)];

      this.$refs.factsPlaceholder.innerHTML = `While you wait, did you know: ${randomElement}?`;
    } else {
      const firstFact = document.getElementById("initial-facts-placeholder");
      this.$refs.factsPlaceholder.innerHTML = firstFact.innerHTML;

      firstLoaderToDelete.remove();
    }

    this.initialLoaderRemoved = true;
  },
};
</script>
