<!-- eslint-disable max-len -->
<template>
  <div class="drawer">
    <div
      class="drawer__watermark fixed right-0 bottom-0 top-0 overflow-hidden pointer-events-none w-full h-full flex flex-col items-end justify-end pr-2 pb-2 pt-12"
    >
      <img
        :src="$constants.images.leopardWatermark"
        class="fade-up flex-grow-0 max-h-full"
        alt=""
      />
    </div>
    <div class="drawer__scroll">
      <div class="drawer__scroll__body z-10 relative">
        <slot></slot>
      </div>
    </div>
    <div
      class="drawer__close absolute top-0 right-0 z-40 padding-app h-16 fade-up flex flex-col justify-center"
    >
      <button
        class="btn btn-square btn-close"
        @click="toggleDrawerState"
      ></button>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "LondoDrawer",
  computed: {
    ...mapState({
      isDrawerOpen: (state) => state.modifiers.drawerOpen,
    }),
  },
  methods: {
    ...mapMutations(["toggleDrawerState"]),
    updateNuxtElementClass() {
      const nuxtElement = document.getElementById("__nuxt");
      if (nuxtElement) {
        if (this.isDrawerOpen) {
          nuxtElement.classList.add("drawer-open");
        } else {
          setTimeout(() => {
            nuxtElement.classList.remove("drawer-open");
          }, 500);
        }
      }
    },
  },
  mounted() {
    this.updateNuxtElementClass();
  },
  watch: {
    isDrawerOpen() {
      this.updateNuxtElementClass();
    },
  },
};
</script>

<style lang="scss" scoped>
.app--submitting-booking {
  .btn-close {
    opacity: 0.3;
    pointer-events: none;
  }
}
.drawer {
  --drawer-width: 83%;
  @apply flex h-full w-full justify-end;
  @include min-width;
  z-index: -1;
  transform: translateZ(0px) translateX(0) rotateY(0deg);
  &__scroll {
    @apply block h-full max-w-full overflow-x-hidden overflow-y-auto relative;
    width: var(--drawer-width);
    z-index: 0;
    &__body {
      @apply flex flex-col justify-center items-center min-h-full flex-grow;
    }
  }
  &__watermark {
    opacity: 0.35;
    z-index: -1;
    width: calc(var(--drawer-width) - 7%);
    img {
      align-self: flex-end;
      z-index: -1;
    }
  }
}

.ios,
.ipad {
  .drawer {
    transform: translateZ(-100px) translateX(0) rotateY(0deg);
  }
}

@screen xs {
  .drawer {
    --drawer-width: 78%;
  }
}
@screen sm {
  .drawer {
    --drawer-width: 65%;
  }
}
@screen md {
  .drawer {
    --drawer-width: 60%;
  }
}
@screen lg {
  .drawer {
    --drawer-width: 44.5%;
  }
}
@screen xl {
  .drawer {
    --drawer-width: 39.5%;
  }
}
@screen xxl {
  .drawer {
    --drawer-width: 36.5%;
  }
}
@screen xxxl {
  .drawer {
    --drawer-width: 32%;
  }
}
</style>
