<!-- eslint-disable max-len -->
<template>
  <div class="navigation" :class="{ 'navigation--minimalist': minimalist }">
    <div v-if="!minimalist" class="menu-logo flex mt-auto mb-2 fade-up">
      <global-link
        to="/"
        class="flex justify-center w-full h-full"
        @click="toggleDrawerState"
      >
        <img
          :src="$constants.images.logo.menu"
          class="menu-logo__image w-auto max-w-full h-auto mt-8 xs:mt-12 sm:mt-16 mb-10 xxl:mb-12"
          alt=""
        />
      </global-link>
    </div>
    <ol type="I" class="navigation__navigation flex flex-col">
      <li
        v-for="(item, index) in menuItems"
        :key="item.route"
        class="fade-up trajan text-lg xs:text-xl xxl:text-2xl leading-loose"
        :style="`--delay: ${((index + 1) * 0.1).toFixed(2)}s;`"
      >
        <global-link
          :to="'/' + item.route"
          v-if="!item.external"
          class="p-2 pb-3 xxl:py-3 flex transition-base"
          @click="closeIfActiveLink(item.route)"
        >
          <div
            class="numeral w-12 text-right tracking-tighter uppercase transition-base"
          >
            {{ item.numeral }}
          </div>
          <div class="menu-text">{{ item.text }}</div>
        </global-link>
        <a
          v-if="item.external"
          :href="item.external"
          target="_blank"
          class="p-2 lg:pb-3 xxl:py-3 flex transition-base"
          @click="toggleDrawerState()"
        >
          <div
            class="numeral w-12 text-right tracking-tighter uppercase transition-base"
          >
            {{ item.numeral }}
          </div>
          <div class="menu-text">{{ item.text }}</div>
        </a>
      </li>
    </ol>
    <div
      v-if="!minimalist"
      class="slogan mb-auto fade-up text-center flex flex-col mt-8 xxl:mt-10"
    >
      <h4 class="din-bold text-2xl xs:text-3xl leading-relaxed uppercase mt-1">
        The Original
      </h4>
      <h5
        class="text-sm xxl:text-base italic text-grey leading-6 mb-6 xs:mb-10 sm:mb-12"
      >
        Pioneering Experiential<br />
        Luxury Since 1926
      </h5>
    </div>
    <div
      class="navigation__footer din text-lg text-grey-600 uppercase flex fade-in mb-1 sm:mb-1"
    >
      <a
        v-for="(item, index) in bottomMenu"
        :key="index"
        :href="item.href"
        target="_blank"
        class="px-4 transition-base"
        @click="toggleDrawerState()"
      >
        {{ item.text }}
      </a>
    </div>
  </div>
</template>

<script>
import { useMenuItems } from "@/composables/useMenuItems";
import store from "@/store";

export default {
  name: "LondoNav",
  setup() {
    const { menuItems } = useMenuItems();

    const minimalist = ref(true);
    const bottomMenu = ref(null);

    const toggleDrawerState = () => {
      store.commit("toggleDrawerState");
    };

    const closeIfActiveLink = (routeName) => {
      // if (routeName === useRoute().name.split(/[.\-_]/)[1]) toggleDrawerState();
      toggleDrawerState();
    };

    return {
      menuItems,
      minimalist,
      bottomMenu,
      toggleDrawerState,
      closeIfActiveLink,
    };
  },
};
</script>

<style lang="scss">
.booking + .navigation,
.booking-enquiry + .navigation {
  display: none !important;
}
.navigation {
  @apply flex flex-col justify-center items-center min-h-full flex-grow;
  &__navigation {
    margin-left: 0em;
  }
  .menu-logo {
    @apply mr-2;
    max-width: 98px;
  }
  li {
    animation-delay: var(--delay);
    a {
      .numeral {
        @apply absolute text-right;
        left: -3.4em;
        color: #ddd;
      }
      &:hover {
        .numeral {
          color: var(--blue);
        }
      }
      &.router-link-exact-active {
        color: $grey-600;
      }
    }
  }
  &__footer {
    &.fade-in {
      animation-delay: 0.1s;
      animation-duration: 0.4s;
    }
    a:hover {
      color: var(--blue);
    }
  }
  &--minimalist {
    .menu-logo,
    .slogan {
      display: none;
    }
    .navigation__navigation {
      @apply my-auto py-12;
    }
  }
}

@screen xs {
  .navigation {
    &__navigation {
      li {
        a {
          .numeral {
            @apply absolute text-right;
            left: -3.3em;
          }
        }
      }
    }
    .menu-logo {
      @apply mr-5;
      max-width: 122px;
    }
  }
}
@screen md {
  .navigation {
    .menu-logo {
      @apply mr-5;
      max-width: 130px;
    }
  }
}
@screen xl {
  .navigation {
    .menu-logo {
      @apply mr-5;
    }
  }
}
@screen xxl {
  .navigation {
    &__navigation {
    }
    .menu-logo {
      @apply mr-4;
      max-width: unset;
    }
  }
}
</style>
