<template>
  <button
    class="btn btn-square btn-menu burger"
    :disabled="disabled"
    @click="toggleDrawerState"
    :class="{ 'burger--light': white }"
  >
    <span class="burger__line"></span>
  </button>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "LondoMenuButton",
  props: {
    disabled: {
      default: false,
      required: false,
    },
    white: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  methods: {
    ...mapMutations(["toggleDrawerState"]),
  },
};
</script>

<style scoped lang="scss">
.burger {
  @apply flex flex-col w-full h-full items-center justify-center;
  &__line,
  &:before,
  &:after {
    @apply bg-black shadow-sm;
    width: 24px;
    height: 2px;
    transition: 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  &__line {
    margin: 5px 0;
  }
  &:before,
  &:after {
    content: "";
  }
  &--light {
    .burger__line,
    &:before,
    &:after {
      @apply bg-white shadow-none;
    }
  }
}

.app--no-touch {
  .burger {
    &:hover {
      .burger__line {
        margin: 7px 0;
      }
      .burger__line,
      &:before,
      &:after {
        @apply shadow;
      }
    }
    &:hover {
      .burger__line,
      &:before,
      &:after {
        @apply shadow-none;
      }
    }
  }
}

.app--perspective {
  .burger {
    &:focus {
      outline: none;
    }
  }
}
</style>
