<!-- eslint-disable max-len -->
<template>
  <transition name="slide-up-down">
    <div
      v-if="checkedExisting && !privacyAccepted"
      class="cookie-banner fixed w-full bottom-0 bg-white padding-app overflow-hidden flex items-center"
    >
      <div
        class="cookie-banner__content width-lg h-full w-full flex flex-row items-center"
      >
        <div class="alert mr-4 leading-tight lg:leading-loose text-sm">
          We use <b>cookies</b> to provide a personalized site experience. By
          using this site, you agree to our
          <NuxtLink
            :to="
              localePath({
                name: 'ConnectSinglePage',
                params: { slug: 'privacy-policy' },
              })
            "
            class="pl-1 text-blue"
          >
            Privacy Policy
          </NuxtLink>
          .
        </div>
        <button
          @click="setConsentProvided"
          class="btn btn-default my-0 mr-3 hidden md:flex"
        >
          Accept
        </button>
        <button
          class="btn btn-square btn-close ml-auto"
          @click="setConsentProvided"
        ></button>
      </div>
    </div>
  </transition>
</template>

<script>
const STORAGE_TYPES = {
  localStorage: "LOCAL_STORAGE",
  cookies: "COOKIE",
};

const CONSENT_STORAGE_KEY = "_privacy_consent_provided";

export default {
  name: "PrivacyConsentBanner",
  data() {
    return {
      selectedStorageType: STORAGE_TYPES.localStorage,
      localStorageSupported: true,
      privacyAccepted: null,
      checkedExisting: false,
      testing: false,
    };
  },
  created() {
    try {
      const test = "__londolozi-check-localStorage";

      if (typeof window !== "undefined" && window.localStorage) {
        window.localStorage.setItem(test, test);
        window.localStorage.removeItem(test);
      }
    } catch (e) {
      this.localStorageSupported = false;
    }

    this.privacyAccepted = this.getPreviousConsentProvided();
  },
  mounted() {
    this.checkedExisting = true;
  },
  methods: {
    getPreviousConsentProvided() {
      let consentVal = false;

      if (process.client && this.localStorageSupported) {
        consentVal = window.localStorage.getItem(CONSENT_STORAGE_KEY);
      } else {
        consentVal = this.parseCookie();
      }

      if (typeof consentVal === "string") {
        consentVal = JSON.parse(consentVal);
      }

      return consentVal;
    },
    setConsentProvided() {
      this.privacyAccepted = true;

      if (this.testing) return;

      if (typeof window !== "undefined" && this.localStorageSupported) {
        window.localStorage.setItem(CONSENT_STORAGE_KEY, true);
      } else {
        const d = new Date();
        d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
        const expires = `expires=${d.toUTCString()}`;
        if (process.client) {
          document.cookie = `${CONSENT_STORAGE_KEY}=${true};${expires};path=/`;
        }
      }
    },
    parseCookie() {
      if (!process.client) {
        return;
      }
      const key = `${CONSENT_STORAGE_KEY}=`;
      const ca = document.cookie.split(";");

      for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i];

        while (c.charAt(0) === " ") {
          c = c.substring(1);
        }

        if (c.indexOf(key) === 0) {
          return Boolean(c.substring(key.length, c.length));
        }
      }

      return false;
    },
  },
};
</script>

<style lang="scss">
.cookie-banner {
  transform: translate3d(0, 0, 0);
  min-height: 4rem;
  z-index: 50;
  box-shadow:
    0 -1px 3px 0 rgba(0, 0, 0, 0.1),
    0 -1px 2px 0 rgba(0, 0, 0, 0.06);
  &__content {
    .alert {
      @apply my-3;
      &:after {
        height: calc(100% - 0.2em);
        top: 0.1em;
      }
    }
    .btn-default {
      height: 42px;
    }
    .btn-close {
      @apply flex-shrink-0;
      width: 44px;
      position: relative;
    }
  }
}
</style>
