<!-- eslint-disable max-len -->
<template>
  <div
    class="booking-enquiry padding-app other-contents"
    :class="[
      {
        'booking-enquiry--loading': formSubmitting,
      },
      { 'booking-enquiry--complete': submitting === 'submitted' },
    ]"
  >
    <transition-group name="fade">
      <div
        class="booking-enquiry__steps"
        key="loadingOutlineComplete"
        :ref="sharpspringForm.formId"
      >
        <form
          autocomplete="on"
          novalidate
          :id="sharpspringForm.formId"
          :ref="sharpspringFormRef"
          @submit="onFormSubmit($event, { event: 'submit_enquiry' })"
        >
          <div
            :id="'booking-enquiry-step-' + (index + 1)"
            class="booking-enquiry__step fade-up"
            v-for="(step, index) in bookingStepsSchema"
            :key="index + 1"
            :style="'--delay: ' + ((index + 2) * 0.1).toFixed(2) + 's'"
            :class="[
              {
                'booking-enquiry__step--active':
                  bookingStep === index && !formSubmitting,
              },
              {
                'booking-enquiry__step--completed':
                  bookingStep > index || thankyouStep || formSubmitting,
              },
            ]"
          >
            <div
              class="booking-enquiry__step__title flex flex-row justify-start trajan text-xl xs:text-xl xxl:text-2xl leading-relaxed"
            >
              <div
                class="numeral text-right tracking-tighter uppercase transition-base"
              >
                {{ step.numeral }}
              </div>
              <span class="title">{{ step.title }}</span>
              <i class="icon icon-check"></i>
            </div>
            <transition-group name="collapse">
              <div
                class="booking-enquiry__step__contents contents collapse-el"
                v-if="index === 0"
                v-show="bookingStep === 0"
                :style="{ 'max-height': '460px' }"
              >
                <p class="booking-enquiry__step__subtitle">
                  Tell us more about your journey
                </p>
                <BookingDetailsForm
                  @fieldClicked="handleFieldClickTracking"
                  :filterState="bookingFilters"
                  filterStateScope="booking"
                  inputIdPrefix="booking-enquiry-enquiry"
                />

                <div class="booking-enquiry__step__footer">
                  <span
                    class="button-tooltip ml-auto"
                    v-tooltip="BookingEnquirytooltip(!bookingFilters.valid)"
                  >
                    <button
                      type="button"
                      class="btn btn-primary"
                      :id="'continue-step-' + bookingStep"
                      :disabled="!bookingFilters.valid"
                      @click="next"
                    >
                      Continue
                    </button>
                  </span>
                </div>
              </div>
              <div
                class="booking-enquiry__step__contents contents collapse-el"
                v-if="index === 1"
                v-show="bookingStep === 1"
                :style="{ 'max-height': '560px' }"
              >
                <p class="booking-enquiry__step__subtitle">
                  We'll need these details to get in touch with you
                </p>
                <ContactDetailForm
                  @fieldClicked="handleFieldClickTracking"
                  :contactState="contact"
                  :showSubmit="false"
                  :renderFormTag="false"
                  :sharpspringForm="$sharpspring.forms.enquiryGuest"
                  contactStateScope="booking"
                />
                <div class="booking-enquiry__step__footer">
                  <button
                    class="btn btn-nav btn-back"
                    @click="back"
                    type="button"
                  ></button>
                  <span
                    class="button-tooltip ml-auto"
                    v-tooltip="
                      BookingEnquirytooltip(
                        !contact.valid || !contact.privacyAccepted,
                        contact.valid && !contact.privacyAccepted
                          ? 'Please accept policy'
                          : 'Please complete fields'
                      )
                    "
                  >
                    <button
                      class="btn btn-primary"
                      :id="'continue-step-' + bookingStep"
                      :disabled="!contact.valid || !contact.privacyAccepted"
                      @click="next"
                      type="button"
                    >
                      Continue
                    </button>
                  </span>
                </div>
              </div>
              <div
                id="booking-enquiry-step-3"
                class="booking-enquiry__step__contents contents collapse-el"
                v-if="index === 2"
                v-show="bookingStep === 2"
                :style="{ 'max-height': '630px' }"
              >
                <transition name="fade">
                  <div
                    class="booking-enquiry__step__contents__loading"
                    v-show="formSubmitting"
                  >
                    <span class="text-center" v-html="step.loading"></span>
                  </div>
                </transition>

                <p class="booking-enquiry__step__subtitle hidden">
                  Anything else you'd like to add?
                </p>
                <div class="booking-enquiry__step__contents__comp">
                  <ExtraInformationForm
                    @fieldClicked="handleFieldClickTracking"
                    :extraInfoState="extra"
                    extraInfoStateScope="booking"
                    signupHeading="Newsletter Sign up"
                  />
                </div>

                <div class="booking-enquiry__step__footer">
                  <button
                    class="btn btn-nav btn-back"
                    @click="back"
                    type="button"
                  ></button>
                  <button
                    class="btn btn-primary"
                    id="send-enquiry"
                    :disabled="!formValid"
                  >
                    Send Enquiry
                  </button>
                </div>
              </div>
            </transition-group>
          </div>
        </form>
        <div ref="script-placeholder"></div>
      </div>
      <div
        class="booking-enquiry__complete pt-2"
        v-if="thankyouStep"
        key="booking-enquiryComplete"
      >
        <div class="h3 mt-0">Thank You</div>
        <p>We've received your enquiry</p>
        <div class="alert alert--success">
          <p>
            We will be in touch with you soon. If you have any other questions
            please don't hesitate to
            <global-link to="/contact">get in touch</global-link>.
          </p>
        </div>

        <button class="btn btn-default w-full" @click="clearFilters">
          Done
        </button>
      </div>
    </transition-group>
  </div>
</template>

<script>
/* eslint-disable operator-linebreak */

import { mapMutations, mapState } from "vuex";
import BookingDetailsForm from "@/components/Enquiries/BookingDetailsForm.vue";
import ContactDetailForm from "@/components/Enquiries/ContactDetailForm.vue";
import ExtraInformationForm from "@/components/Enquiries/ExtraInformationForm.vue";
import enquiryActions from "@/store/modules/enquiry-mutation-types";
import pageMappings from "@/util/pageMapping";
import forms from "@/mixins/forms";
import { defaultBookingFilters } from "@/store/modules/enquiry";
import tracking from "@/mixins/tracking";

export default {
  name: "LondoBookingEnquiry",
  components: {
    ContactDetailForm,
    BookingDetailsForm,
    ExtraInformationForm,
  },
  mixins: [tracking, forms],
  data() {
    return {
      pageMappings,
      sharpspringForm: this.$sharpspring.forms.booking,
      actionMapping: enquiryActions,
      bookingStepsSchema: [
        {
          index: 0,
          title: "Your Journey",
          numeral: "i",
        },
        {
          index: 1,
          title: "Your Details",
          numeral: "ii",
        },
        {
          index: 2,
          title: "Extras",
          numeral: "iii",
          loading: "One moment...",
        },
      ],
      tooltipOptions: {
        content: "Please complete fields",
        trigger: "hover click",
        hideOnTargetClick: false,
        container: ".app",
      },
      edForm: true,
    };
  },
  methods: {
    ...mapMutations(["toggleDrawerState"]),
    next() {
      this.trackEvent({
        event: "form_section_complete",
        form_section: this.bookingStepsSchema[this.bookingStep].title,
      });

      this.$store.commit(
        this.actionMapping.booking.stepIndex,
        this.bookingStep + 1
      );
    },
    back() {
      this.$store.commit(
        this.actionMapping.booking.stepIndex,
        this.bookingStep - 1
      );
    },
    clearFilters() {
      console.log("%c%s", "color: Peru;", "BookingEnquiery clearFilters");
      this.$store.commit(this.actionMapping.reset, {
        scope: "booking",
        filters: defaultBookingFilters,
      });
      this.toggleSubmittingBooking(false);
      this.submitting = null;

      this.toggleDrawerState();
    },
    handleFieldClickTracking(fieldName) {
      this.trackEvent({
        event: "form_interaction",
        form_type: "booking_sidebar",
        form_field: fieldName,
      });
    },
    BookingEnquirytooltip(enabled, msg) {
      if (!enabled) return false;

      const tooltip = { content: "Please complete fields" };
      if (msg) tooltip.content = msg;

      return tooltip;
    },
    toggleSubmittingBooking(submitting = true) {
      this.$store.commit("toggleModifier", {
        key: "submittingBooking",
        val: submitting,
      });
    },
  },
  computed: {
    ...mapState({
      bookingFilters: (state) => state.enquiry.booking.filters,
      extra: (state) => state.enquiry.booking.extra,
      contact: (state) => state.enquiry.booking,
      bookingStep: (state) => state.enquiry.booking.stepIndex,
      doneIndex: (state) => state.enquiry.booking.doneIndex,
      bookingOpen: (state) => state.modifiers.showBooking,
      bookingSubmitting: (state) => state.modifiers.submittingBooking,
    }),
    thankyouStep() {
      return this.bookingStep === this.doneIndex;
    },
    sharpspringFormRef() {
      return `${this.sharpspringForm.formId}-form`;
    },
    formValid() {
      return (
        this.bookingFilters.valid &&
        this.contact.valid &&
        this.bookingFilters.valid &&
        this.contact.privacyAccepted
      );
    },
    formSubmitting() {
      return this.submitting || this.bookingSubmitting;
    },
  },
  watch: {
    submitting() {
      if (this.submitting === "submitting") {
        this.toggleSubmittingBooking();
      } else if (this.submitting === "submitted") {
        this.$store.commit(
          this.actionMapping.booking.stepIndex,
          this.doneIndex
        );
        this.toggleSubmittingBooking(false);
        this.$router.push("/booking/thank-you");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.collapse-el {
  max-height: 560px;
}
.collapse-enter-active {
  animation: collapse reverse 0.4s ease-in;
}
.collapse-leave-active {
  animation: collapse 0.4s ease-out;
}
@keyframes collapse {
  from {
    overflow: visible;
  }
  to {
    max-height: 0px;
    overflow: hidden;
  }
}

.booking-enquiry {
  @apply flex flex-col pt-14 pb-8;
  width: 94%;
  max-width: 600px;
  padding-left: 2.1rem !important;
  &__step {
    @apply pb-3 relative;
    &.fade-up {
      animation-delay: var(--delay);
    }
    &__title {
      @include transition-base;
      @apply text-grey-300;
      text-shadow: 0 1px 1px white;
      opacity: 0.7;
      .numeral {
        @apply text-left;
        color: #ddd;
        width: 1.8em;
        letter-spacing: -0.1em;
        padding-right: 0.7rem;
        margin-left: -2.3rem;
        text-align: right;
      }
      i {
        @apply flex items-center pb-1 pl-4 mr-auto text-2xl text-transparent;
        @include transition-base;
      }
    }
    &__subtitle {
      @apply italic text-grey-400 text-sm leading-relaxed;
      a {
        color: var(--blue);
      }
    }
    &__contents {
      @apply relative mb-3;
      &__loading {
        @apply inset-0 absolute z-20 flex flex-col items-center justify-center;
        span {
          @apply mt-5 italic text-grey-400;
        }
        &:before {
          @include loading-spinner-pseudo;
          @apply relative left-auto top-auto;
        }
      }
      &__comp {
        @apply relative z-10;
        opacity: 1;
        transition: opacity 0.3s ease-out;
        .form {
          @apply mb-3;
        }
        .collapsible__content {
          li {
            @apply text-sm;
            margin-bottom: 0.75rem !important;
            &:last-child {
              margin-bottom: 0 !important;
            }
          }
        }
      }
    }
    &__details {
      @apply flex flex-col leading-relaxed mt-2 mb-6;
      .title {
        @apply flex font-din uppercase items-center text-grey-900 font-bold leading-normal;
        font-size: 1.4rem;
      }
      p {
        @apply mb-0;
      }
    }
    &__footer {
      @apply flex w-full mb-2 mt-6;
      .btn-back {
        @apply mr-auto;
        &:before {
          padding-left: 0 !important;
        }
        &:hover {
          @apply -ml-2 pr-5;
        }
      }
      .btn-primary {
        @apply ml-auto;
      }
    }
    &--completed {
      .booking-enquiry__step__title {
        opacity: 0.3;
        i {
          color: var(--blue);
          /* @apply text-blue; */
        }
      }
    }
    &--active {
      box-shadow: inset 0 -1px 0px 0px #ebebeb;
      @apply mb-6 z-10;
      .booking-enquiry__step__title {
        @apply text-black;
        opacity: 1;
        .numeral {
          @apply text-grey-200;
        }
      }
    }
    &#booking-enquiry-step-3 {
      :deep(.londo-form) {
        .londo-input-textarea {
          @apply mt-2 mb-3;
          .londo-input__group {
            margin-top: -0.2rem;
            label {
              display: none;
            }
          }
        }
        .londo-checkbox-group {
          @apply flex-wrap mt-0;
          &:before {
            @apply italic text-grey-400 text-sm leading-normal w-full;
            content: "Would you like to sign up for our newsletters?";
            display: none;
          }
        }
      }
    }
  }
  &--loading,
  &--complete {
    .booking-enquiry__step {
      &__contents {
        .booking-enquiry__step__footer,
        &__comp {
          @apply pointer-events-none;
          opacity: 0.1;
        }
      }
    }
  }
  .londo-input {
    .londo-input__group {
      &__label {
        flex: 0 1 33%;
      }
      &__input {
        min-width: 66%;
      }
    }
  }
}

@screen xs {
  .booking-enquiry {
    width: 90%;
    padding-left: 3rem !important;
    padding-right: 3rem !important;
    &__step {
      &__title {
        .numeral {
          width: 2.5rem;
          padding-right: 1rem;
          margin-left: -2.5rem;
          text-align: right;
        }
      }
    }
  }
}

@screen md {
  .booking-enquiry {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
    &__step {
      &__title {
        .numeral {
          width: 2.5rem;
          padding-right: 1rem;
          margin-left: -2.5rem;
          text-align: right;
        }
      }
    }
  }
}

@screen xl {
  .booking-enquiry {
    &__step {
      &__title {
        .numeral {
          width: 3rem;
          padding-right: 1.5rem;
          margin-left: -3rem;
        }
      }
    }
  }
}
</style>
