<!-- eslint-disable no-mixed-spaces-and-tabs -->
<!-- eslint-disable no-tabs -->
<!-- eslint-disable max-len -->
<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="43.3px"
    height="44.1px"
    viewBox="-91.4 750.2 43.3 44.1"
    style="enable-background: new -91.4 750.2 43.3 44.1"
    xml:space="preserve"
  >
    <path
      class="st0"
      d="M-70.6,763.6c-0.5-0.8-1.5-4.5-2.2-4.7c-0.6-0.3-1.8,2-1.9,2.3c-0.3,1.1,0.3,1.2-0.7,1.8
	c0.2-0.1,0.3,2.4,0.3,2.5c0,0.2-0.3,0.3-0.3,0.6c0,0.3,0.6,0.6,0.6,0.8c0,0.4-1,0.9-1,1.5c0.1,1,0.4,0.2,1,1c0.1,0.2,0.7,0,0.8,0.1
	c0.2,0.2-0.1,0.8-0.3,0.6c-0.3-0.3,0.2,0.7,0.4,0.9c0.5,0.5,1.8,0.1,2,1.1c0.3,1.5-1,1.3-1.9,1.3c-0.4,0-4-0.8-4,0.1
	c0.1,0.2,0.3,0.3,0.4,0.3c0.2,0.2-1.3,0.3-1.2,0.5c0.2,0.2,0.5,0.3,0.8,0.3c0.2,0.1-0.8,1-0.7,1.2s0.3,0.3,0.5,0.5
	c0.4,0.7,0.8,0,1.5,0.4c0.2,0.1,0,1,0.2,1.1c0.2,0.2,0.9-0.2,1.2,0c0.3,0.1,0.3,0.6,0.6,0.7c0.4,0.2,0.7-0.5,1.1-0.3
	c0.4,0.2-0.2,1.1-0.2,1.4c-0.1-0.8,2.3,0.4,1.9,0.5c0.3,0,0.4,0.3,0.9-0.1c0.8-0.7,0-3,0-3.6c0-1.2,0.3-1.4,0.7-2.5
	c0.3-1.1,0.7-3.3,0.3-4.3c0-0.1-0.9-0.3-1-0.5c-0.3-0.6-0.3-1.3-0.3-2C-71.1,765.8-70.7,764.7-70.6,763.6"
    />
    <path
      class="st0"
      d="M-69.1,764.2c0.4-0.9,1.3-5,2.2-5.3c0.7-0.3,1.6,2,1.6,2.2c0.2,0.7,0.1,1.4,0.8,1.9c-0.2-0.1-0.3,2.4-0.3,2.5
	c0,0.3,0.3,0.3,0.3,0.7c0,0.3-0.5,0.6-0.5,0.8c0,0.4,0.9,0.9,0.9,1.5c-0.1,0.9,0,0.2-0.6,0.9c-0.2,0.3-1,0-1.1,0.2
	c-0.1,0.2,0.1,0.5,0,0.5c0.3-0.3,0.1,0.7-0.2,0.9c-0.3,0.3-1.2-0.1-1.7,0.5c-1.1,1.5,0.5,1.9,1.6,1.9c0.3,0,3.6-0.8,3.6,0.1
	c-0.1,0.2-0.2,0.3-0.4,0.3c-0.2,0.2,1.2,0.3,1.1,0.5c-0.2,0.2-0.4,0.3-0.7,0.3c0,0,0.9,1.5-0.1,2.2c-0.6-0.4-0.6-0.4-1.1,0
	c0,0.4-0.1,0.8-0.2,1.1c-0.2,0-0.4,0-0.5,0c-0.9,0.8-1.3,0.1-2.1,0.4c-0.4,0.2,0.2,1.1,0.1,1.4c0.1-1-2.4,0.4-2.1,0.6
	c-1.2-0.7-0.5-2.4-0.5-3.6c0.1-2.2-0.8-4.6-0.9-7.1C-68.2,769.3-68.5,765.5-69.1,764.2"
    />
    <path
      class="st1"
      d="M-81.3,792.5c0,0.2,0.1,0.4,0.2,0.5c-0.3,0-0.6-0.1-0.9-0.2C-81.9,792.4-81.7,792.3-81.3,792.5 M-78.8,792
	c-0.3,0.1-0.5,0.1-0.7-0.2C-79.2,791.6-79,791.6-78.8,792 M-76.3,791.2c-0.2,0.1-0.4,0.2-0.6,0.3C-76.9,791.1-76.7,791-76.3,791.2
	 M-80.7,790.9c0.3,0.3,0.4,0.6,0.4,1C-81.3,792.4-81.9,790.7-80.7,790.9 M-75.9,789.8c0.3,0,0.5,0.1,0.4,0.4
	C-75.7,790.1-75.8,789.9-75.9,789.8 M-77.2,789.8c0,0.3-0.2,0.8-0.6,0.3C-77.9,789.7-77.7,789.7-77.2,789.8 M-77.1,787.9
	c0.3,0.1,0.5-0.2,0.4,0.3C-77,788.3-77.1,788.2-77.1,787.9 M-88.9,783.9c0.3,0,0.7,0,1,0.2C-88.3,784.2-88.6,784.1-88.9,783.9
	 M-78.7,784.9c-0.1,0.5-3,0.1-2.9-1C-81.4,782.2-78.8,784.3-78.7,784.9 M-90.4,783.3C-90.1,783.2-90.1,783.2-90.4,783.3 M-87,785.9
	c0.6,0.1-0.6-1-0.7-1c0,0,0.3,0.3,0.2,0.5c-1.1-0.4-2-1-3.1-1.3c0.4-0.3,2.9,0.4,3.1,0.5c0.5,0-2.8-2.2-2.9-2c1-0.2,2.3,0.8,2.9,1.1
	c1.2,0.7,2,1.3,3.4,1.7c1.1,0.3,3,0.7,4.4,1.1c0.5,0.2,1.9,0.6,2.1,1.1c0.4,0.9-2.4,0.2-2.9,0.2c-1.3-0.1-2.5-0.3-3.6-0.6
	c-0.5-0.2-2.7-1.2-3-1.1c0.3,1.9,3.8,2.2,5,2.6c1,0.3,4.1,1,4.1,1.9c-0.6,0.3-2.4-0.4-3.2-0.5c-0.9-0.1-1.9,0.1-2.8-0.1
	c0.7,0.5,1.7,0.6,2.6,0.9c-0.7,0.5-0.8,0.9-0.2,1.1l-0.9,0.4c0.3,0.7,0.4,0,0,0.7c-0.1,0.3,1.1,0.4,1,0.8c-0.6,0.2-1.1,0.3-1.7,0.3
	c-1,0.3-1.4,0-1-1.1c-0.4-1.1-0.5-2.4-1-3.5c0.4,0,0.7-0.2,0.9-0.5c-0.4,0-0.7,0.1-1.1,0.2c-0.3-0.5-0.6-0.9-1-1.2
	c-1.2-0.7-2.3-1.1-3.7-1.3c0.7-0.3,0-1.7-0.6-1c0.1,0.1,0.2,0.2,0.4,0.2c-0.2,0.5-0.4,0.6-0.8,0.2c0-0.2,0.1-0.3,0.3-0.2
	c-0.8-0.4,0.1-0.9,0.4-1c0.6-0.2,1.8,0.7,2.3,0.9c-0.5-0.8-1.7-0.9-2.4-1.3C-89.4,784.7-88,785.2-87,785.9 M-81.1,781.9
	c0.5,0,0.7,0.2,0.5,0.7C-81.4,782.9-81,782.3-81.1,781.9 M-81.9,781.5c-1,1.1-1.2-0.6-1.3-0.7C-82.9,781-81.6,779.9-81.9,781.5
	 M-86.7,780.1C-86,779.9-86.9,780.5-86.7,780.1 M-85.8,780.2c1-1.1,1.2,0.3,1.4,0.3C-84.7,780.5-85.7,781.4-85.8,780.2 M-86.3,779.6
	C-87,779.7-86.3,778.9-86.3,779.6 M-87.5,778.8C-87.3,778.9-87.3,778.9-87.5,778.8z M-77.7,779.1c-0.1,0.3-0.3,0.4-0.6,0.4
	c-0.1-0.4,0-0.6,0.4-0.8C-77.9,778.8-77.8,778.9-77.7,779.1 M-79.6,778.7c0,0,0.7,0,0.2,0.2C-79.6,778.9-79.6,778.9-79.6,778.7z
	 M-85.4,778.8c0.2,0.6-0.9,0.3-0.6,0C-85.9,778.6-85.7,778.6-85.4,778.8 M-74.3,778.6c0,0,0.5,0,0.3,0.2
	C-74.2,778.9-74.3,778.8-74.3,778.6 M-83.6,778.5c0.1-0.1-0.7,0.9-0.6,0.1C-84,778.5-83.8,778.5-83.6,778.5 M-78,777.9
	c0,0.4-1.1,1.2-1.2,0.5C-79.4,777.8-78.2,777.8-78,777.9 M-84.4,777.9c-0.1,0.5-1.2,0.9-1.2,0C-85.3,777.6-84.8,777.6-84.4,777.9
	 M-76.2,777.5C-76,777.2-76.1,777.7-76.2,777.5 M-82.5,777.4c-0.1,0.1,0.2,1-0.5,0.3C-82.8,777.6-82.7,777.5-82.5,777.4
	 M-77.7,777.2c0.4-0.2,0.8,0,1,0.4C-77.1,777.5-77.4,777.4-77.7,777.2 M-85.7,777.4c-0.7,1.5-0.5,0.3-1.3,0.4
	C-86.8,777.5-86,776.7-85.7,777.4 M-83.3,777c0.1,0.6-0.7,0.8-1.1,0.6C-84.2,776.9-83.9,776.7-83.3,777 M-78.3,777
	C-80.8,778.6-79.2,775.6-78.3,777 M-77.2,776.5C-76.5,776.2-77,777.1-77.2,776.5 M-78.8,775.9c0.2,0.1,0.4,0.3,0.6,0.4
	c-0.2,0-0.5,0.1-0.7,0.1C-78.8,776.2-78.8,776-78.8,775.9 M-84.4,775.9c0.2,0.2-0.5,1.1-0.9,0.7C-85,776.3-84.7,776.1-84.4,775.9
	 M-80.8,775.7c-0.1,0.6-0.4,0.8-1,0.7C-81.8,775.9-81.4,775.6-80.8,775.7 M-79.9,775.3c0.7,0-0.2,0.3,0.1,0.8
	C-80.4,775.8-80,775.7-79.9,775.3 M-82.3,775.1c-0.3,1.3-0.8,1.1-1.6,1C-83.6,775.5-83,775.2-82.3,775.1 M-80,774.7
	c0,0.3-0.1,1.3-0.8,0.5C-80.7,774.7-80.4,774.6-80,774.7 M-87.6,781.7c-0.1-0.9-1.2-1-1.9-1.2c0.6-0.9,0.5-0.3,1.3-0.2
	c0-0.3,0-0.5-0.2-0.7c1-0.3,0.8,0.3,0.8,1c1.1,0.2,0.6,0.4,1.6,1c0.8,0.4,2.4,0.3,3.1,0.8c2,1.3,0,1.7-1.6,1.4
	c-0.5-0.1-1.7-0.4-2.2-0.7c-0.3-0.2-0.6-1-1-1.1c-0.4-0.1-0.7,0.6-0.5,0.7c-0.7-0.5-0.3-0.8-0.9-1.3
	C-88.8,781.3-87.7,781.7-87.6,781.7 M-73.2,779.5C-73.7,779.1-73.3,779.8-73.2,779.5 M-73.1,767.2c-0.5-0.2,1.3,0.9,0.6,0.7
	c0.3,0.2,0.5,0.3,0.9,0.2c-0.5-1.3,0.1-2-1.3-2.4c-0.1-0.2,0.4-1.1,0.2-1.6c-0.4,0.1-2.3,1-2.6,0.8c0.1-0.3,1.6-1.4,1.9-1.4
	c0,0.3-0.1,0.6-0.2,0.9c0.3-0.3,0.7-0.6,1.2-0.9c0-0.2-0.7-1.3-0.3-2.1c-1.3,1.9-1.5-1-0.2-1.2c0.2,0.3,0.3,0.7,0.2,1.1
	c1.1,0.3,1.7,0.8,1.6,2.4c0.2,0,0.3-0.1,0.4-0.3c1,0.3-0.4,4.7,0.1,5.5c1.6,2.4,2.5-2,2.1-2.2c-0.1,0.3-0.2,0.5-0.3,0.7
	c-0.4-1.8-0.6-3,0.8-3.1c0.3-0.3,0-1,0.2-1.4c0.6-0.9,0.6-0.7,1.4-0.9c0.1-0.3,0.2-0.7,0.2-1.1c1.2-0.3,1.3,3.4-0.1,1.2
	c0.1,0.6,0.3,2-0.3,2c0.3,0.3,0.7,0.6,1,1c0-0.3,0-0.5-0.1-0.8c0.4-0.1,1.8,1.2,1.9,1.4c-0.3,0.1-2.6-0.7-2.6-0.8
	c-0.6,0.2,0.3,1.3,0.2,1.5c-1.6,0.3-0.3,1.1-1.3,2.4c0.3,0,0.5,0.1,0.8,0.1c0-0.2,0-0.3-0.1-0.5c0.2,0,0.3,0,0.5-0.1
	c0.1,0.3,1.2,0.6,1.1,0.9c-0.3,0-1.9,0.2-0.3,0.2c-0.2,0.2-0.5,0.3-0.8,0.3c0,0.3-0.2,0.6-0.2,1.3c0.3,0,0.5,0.1,0.8,0.2
	c-0.9,0.2-2.8,0.5-1.7,1.9c0.7,0.9,2.2-0.1,3.1,0.2c1,0.4,1,1.6,1.4,2c-0.1-0.1,0.1,0.6,0.1,0.6c0.1,0.3,0.7-0.4,0.8-0.4
	c0,1.3-0.9,0.6-1.5,1.6c0.3,0.2,0.5-0.4,0.8-0.4c-0.2,0.3-0.2,0.5,0.2,0.5c-0.3,0.7-1.5,0.1-1.3,1.2c-0.3-0.1-0.5-0.2-0.8-0.2
	c0-0.2,0.1-0.3,0.3-0.2c0-0.3-0.9-1-1.3-0.4c0.5,0.1,0.7,0.4,0.7,1c-0.6-0.1-1.2-0.2-1.7-0.5c0,0.4,0.1,1-0.3,1.3
	c0.2-0.1,0.4-0.1,0.6-0.1c-0.2,0.3-0.4,0.5-0.8,0.6c0.1-0.2,0.1-0.3,0-0.5c-0.1,0.1-0.2,0.2-0.2,0.4c-1,0,0.9-0.7-0.2-1.2
	c-0.1,0-1.4,0.2-1.5,0.2c-0.1-1-0.2-2.2,1-2.1c-0.2-0.3-0.4-0.3-0.7-0.2c0.4-0.9-0.3-1.7-1.2-1.6c-0.8,0.1-1.1,1.1-1.5,1.5
	c0.1,0.4,0.6-0.3,0.7,0.4c0.1,0.6,0.2,1.1,0.2,1.8c-0.7-0.1-0.5-0.1-0.8,0.1c-1-0.3-0.2,0.1-0.8,0c0.1-0.2,0.1-0.3-0.1-0.3
	c-0.2,0.5,0,0.8,0.5,1c-0.2,0.5-0.4-0.2-0.6,0.3c-0.3-0.1-0.6-0.3-0.8-0.6c0.3-0.1,0.4,0,0.6,0.2c-0.4-0.4-0.4-0.8-0.3-1.3
	c-0.5,0.3-1.1,0.5-1.6,0.6c-0.5-0.3,0.6-1,0.6-1c-0.4-0.4-1,0-1.2,0.1c-0.1,1.1-0.1,0-0.3,0.7c0.3,0.4,0.8-0.2,0.3,0.6
	c0.3,0-1.9-2-1.9-1.8c0.5-0.3,0.2-0.8,1-0.1c0.3-0.6-1.1-0.9-1-0.6c-0.3-0.6-0.4-0.1-0.4-1c0.8-0.2,0.2,0.7,1-0.2
	c0.9-1,0.3-1.4,1.8-1.7c0.6-0.1,1.8,0.3,2.2-0.4c0.8-1.5-1.3-1.7-2.2-1.4c0-0.4,0.7-0.2,0.4-0.7c0.2,0.2,0.4,0.3,0.7,0.2
	c-0.4-0.4,0.1-0.9,0.6-0.6c0-0.5-0.2-0.9-0.5-1.2c0.1,0,0.2,0,0.3,0c-0.2-1.2-1.1,0.8,0-0.6c-0.1-0.2-0.2-0.2-0.4-0.2
	c0.1-0.3,0.3-0.4,0.6-0.3C-72.8,767.7-72.9,767.4-73.1,767.2 M-73.3,778.9C-73.2,778.9-73.3,779.5-73.3,778.9 M-73.6,778.2
	c0.3,0.1,0.4,0.3,0.4,0.5C-73.6,778.7-73.7,778.5-73.6,778.2 M-50.6,782.9c-0.2-0.1-0.8-0.5-0.7-0.5c-0.8-0.1-0.8,0.7-1.3,0.9
	c-0.9,0.5-2,0.5-3,0.7c-2.2,0.3-2.3-0.7-0.2-1.2c0.9-0.2,1.7-0.4,2.4-0.9c0.9-0.5,0.5-1,1.6-1c0.2-0.3-0.1-1.9,0.9-1
	c-0.1,0.2-0.1,0.4-0.2,0.6c0.4-0.1,0.8-0.2,1.2-0.4c0.4,1.3-1.5,0.5-1.6,1.8c0.5-0.1,0.9-0.3,1.4-0.4
	C-50.4,781.5-50.5,782.6-50.6,782.9 M-59.2,774.8c0.4-0.1,0.7,0.1,0.7,0.5C-58.7,775.9-59.7,775.3-59.2,774.8 M-55.4,776.1
	c-0.4,0.9-1.5-0.2-1.6-0.7C-57.1,774.7-55.5,775.5-55.4,776.1 M-59.3,775.5c0,0.1,0.3,0.8-0.2,0.8C-59.2,775.9-60,775.4-59.3,775.5
	 M-57.5,776.6c-0.5,0.1-0.9-0.2-1-0.7C-58.1,775.7-57.3,776.1-57.5,776.6 M-55.1,775.9c0.4,0.1,0.9,0.3,1.2,0.5
	C-54.2,777.7-55,775.9-55.1,775.9 M-60.4,776.4c-0.2,0-0.5,0-0.7,0C-60.9,776.2-60.3,775.8-60.4,776.4 M-62.4,776.5
	C-62.1,776.5-62.1,777.1-62.4,776.5z M-59.5,776.9c-0.7,0.8-0.3,1.3-1.5,0.2C-60.5,776.7-60,776.7-59.5,776.9 M-54.9,777.8
	C-56.9,778.9-55.5,775.3-54.9,777.8 M-61.5,777.2c-0.3,0.3-0.6,0.4-1,0.5C-62.3,777.3-62,777.2-61.5,777.2 M-63.3,777.6
	C-63,777.5-63,777.5-63.3,777.6 M-56.8,777.5c0.5-0.1,0.3,0.2,0.4,0.4C-56.7,778-56.8,777.8-56.8,777.5 M-53.6,778
	c-0.1,0.2-0.1,0.5-0.2,0.7C-55.7,777.9-54.5,778-53.6,778 M-61.2,778c0.2-0.1,1.2-0.1,1.2,0.4C-60,779.2-61.2,778.6-61.2,778
	 M-64.1,778.3c0.1,0.2,0,0.4-0.2,0.5c-0.2-0.1-0.3-0.2-0.3-0.3C-64.5,778.3-64.3,778.3-64.1,778.3 M-66,778.4
	c0.1,0.2,0.8,0.1-0.1,0.5C-66.1,778.8-66,778.6-66,778.4 M-55.7,778.7c0.2,0,0.4,0,0.6,0.1C-55,779.1-55.9,779.1-55.7,778.7
	 M-65.3,778.8c0.3,0.1,0.4-0.1,0.4,0.2C-65.1,779-65.2,778.9-65.3,778.8 M-61,778.9c0,0.2,0.1,0.5,0.1,0.7
	C-61.7,779.8-61.6,778.5-61,778.9 M-60,778.8c0.2-0.1,0.3,0,0.3,0.2C-59.9,779.1-60,779-60,778.8 M-53.1,779
	c-0.1,0.3-0.8,0.5-0.7-0.1C-53.6,778.8-53.3,778.8-53.1,779 M-51.9,778.9C-51.8,779.1-51.8,779.1-51.9,778.9z M-66.2,779.1
	C-65.9,779.4-65.9,779.4-66.2,779.1 M-65.9,779.4C-65.7,779.5-65.7,780-65.9,779.4 M-52.9,779.5c0.1,0.1,0.3-0.1,0.2,0.3
	C-53,779.8-53.1,779.7-52.9,779.5 M-61.5,779.8c0.4-0.1,1,0.6,1.1,0.8c0.6,0.9,0.2,1.3-0.7,2.4c-0.7,1-1.6,1.5-2.3,2.2
	c-1,1-1.2,2-1.9,3.1c-1.3-1.3-2.4-4.2-2.2-5.7c-0.3,0.1-0.5-0.1-0.5-0.4c0.3,0,0.6,0,1,0c0.2,0.8,0.1,0.2,0.2,0.2
	c1.1,0.1,2.6,0,3.6-0.1c1.4-0.2,0.7-0.1,1.8-0.9C-61.4,781.2-61.6,780.3-61.5,779.8 M-67.5,779.9C-67.4,779.7-67.4,780-67.5,779.9
	C-67.4,780-67.5,780-67.5,779.9 M-53.4,780.3C-53.7,783.3-56,778.1-53.4,780.3 M-52.7,780.2C-52.6,780.4-52.7,780.5-52.7,780.2
	 M-56.2,781.8c-1.6,0.2-1-1.1-0.8-1C-56.6,780.9-55.4,781.1-56.2,781.8 M-58.2,782.1c0.1,0.4,0.1,0.9-0.5,0.7
	C-58.8,782.3-58.7,782-58.2,782.1 M-48.7,783.1c-1.1,0.3-2.8,0.6-3,1.7c1-0.3,2.1-0.9,3.2-0.6c-1,0.4-2,1-3.1,1.3
	c0-0.2,0.1-0.3,0.3-0.4c-0.4,0-1,0.5-1,0.9c1.4-0.3,2.5-1.1,3.9-1.3c-0.8,0.6-1.8,0.6-2.5,1.3c1-0.1,1.7-0.9,2.7-1
	c0.4,0.8-0.3,2.1-0.5,1.3c0-0.2,0.2-0.3,0.4-0.2c-0.2-1.1-1.5,0.5-0.7,0.7c-1.1,0.3-2.1,0.4-3.1,0.9c-1.1,0.6-1.4,1.5-2.6,1.4
	c0,0.1,0.5,0.7,0.5,0.5c-0.3,0.7-0.6,1.3-0.8,2c-0.5,2.1,0,2.7-2.6,2.1c0-0.1,0.9-0.7,0.9-0.5c-0.1-0.2-0.3-0.3-0.4-0.5
	c0.2-0.1,0.3-0.2,0.4-0.3c-0.2-0.8-0.4,0.2-0.9-0.3c-0.3-1.3,1.5,1.9-0.2-1.2c0.9-0.3,1.7-0.5,2.6-0.8c-1.3-0.2-1.7-0.1-3,0
	c-0.9,0.1-2,0.7-2.8,0.6c-0.7-0.8,3.6-1.7,4.2-1.9c1.3-0.3,4.3-1,4.7-2.6c0-0.1-4.9,1.9-5.7,2c-0.7,0.1-3.9,0-3.8-0.4
	c0-0.5,1.9-1,2.2-1.1c0.7-0.2,1.4-0.4,2.2-0.6c0.5-0.2,1.3,0.1,1.6,0c0.6-0.2,0.3-0.6,0.9-0.8C-54.2,785.3-49,782.1-48.7,783.1
	 M-60.1,785.6c-0.2-0.1-0.3-0.3-0.5-0.4c0.2-0.4,3-2.6,2.9-1.2C-57.7,785.4-59.7,784.4-60.1,785.6 M-49.1,783.5
	C-48.9,783.2-49,783.7-49.1,783.5 M-50.5,784c-0.2,0.3-0.5,0.4-0.9,0.4C-51.1,784.2-50.8,784-50.5,784 M-62.6,788.1
	c0.2,0,0.3,0,0.5,0C-62.3,788.8-62.5,788.1-62.6,788.1z M-63.3,790.1c-0.1,0.3-0.3,0.3-0.5,0.2C-63.7,789.9-63.5,789.9-63.3,790.1
	 M-61.5,790.1c-0.2,0.3-0.4,0.3-0.7,0.2C-62.3,789.9-61.5,789.7-61.5,790.1 M-59,791.9C-59.3,788.6-56.9,793.9-59,791.9
	 M-62.3,791.6c-0.2,0-0.3-0.1-0.4-0.2C-62.5,791.1-62.4,791.2-62.3,791.6 M-59.8,792c-0.2,0.3-0.4,0.3-0.7,0.2
	C-60.3,791.8-60.1,791.7-59.8,792 M-57.9,792.5c0.3,0,0.5,0,0.6,0.2c-0.2,0.3-0.5,0.4-0.9,0.4C-58.1,792.9-58,792.7-57.9,792.5
	 M-57.7,774.6c0.2,0,0.4,0,0.5,0.2C-57.4,774.8-57.6,774.8-57.7,774.6 M-53,778.3c-0.3-0.2-0.5-0.4-0.5-0.9c0.5-0.4,1.1,0.2,1.5,0.5
	C-52.3,778-52.7,778.1-53,778.3 M-59.1,774.1c0.2,0.1,0.5,0.2,0.7,0.2C-58.7,774.4-58.9,774.4-59.1,774.1 M-61.8,773.9
	c-0.2-0.2,0.6,0.5,0.2,0.4C-61.8,774.2-61.9,774.1-61.8,773.9 M-60.3,773.8c0.2,0.1,0.3,0.1,0.5,0.2
	C-60.1,774.3-60.2,774.2-60.3,773.8 M-77.6,773.8c0.3,0,0.3,0.1,0.2,0.3C-77.8,774.2-77.9,774.1-77.6,773.8 M-79,773.8
	c-0.1,0.3-0.2,0.4-0.5,0.2C-79.3,773.9-79.1,773.8-79,773.8 M-61.1,773.7C-60.9,773.8-60.9,773.8-61.1,773.7z M-78.3,773.7
	C-78.1,773.6-78.1,773.6-78.3,773.7 M-63.7,773.1C-63.4,772.4-63.8,773.9-63.7,773.1 M-61.2,771.1c-0.2,0.1-0.1,0.5-0.7,0.3
	C-61.8,771.2-61.5,771-61.2,771.1 M-78.7,771.1c0.3,0,0.4,0.1,0.4,0.4C-78.6,771.4-78.7,771.2-78.7,771.1 M-63.3,770.4
	c0,0,0.9,0.1,0.4,0.5C-63.2,770.9-63.3,770.7-63.3,770.4 M-65.3,770.2C-65.4,770.5-65.9,770.2-65.3,770.2 M-75.5,770.3
	C-75.4,770.1-75.3,770.5-75.5,770.3 M-66,770.1c0,0,0.9,0.4-0.1,0.4C-66,770.4-66,770.3-66,770.1 M-76.3,769.8
	c0.1,0.1,0.7,0.6-0.2,0.5C-76.4,770.1-76.4,769.9-76.3,769.8 M-63.6,769.8c-0.1,0.1-0.2,0.3-0.3,0.4c0-0.1-0.1-0.2-0.2-0.3
	C-63.9,769.7-63.8,769.7-63.6,769.8 M-74,769.6C-73.5,769.8-73.8,769.7-74,769.6z M-73.4,769.5c0.2,0.1,0.3,0.3,0.2,0.5
	C-74,770.1-73.3,769.7-73.4,769.5 M-81.9,769.8c-0.2-0.1-0.4-0.1-0.6-0.2C-82.4,769.3-81.7,769.3-81.9,769.8 M-62.7,769.2
	c0.2,0,0.3,0,0.5,0c0,0.2,0,0.3-0.1,0.5C-62.6,769.6-62.7,769.5-62.7,769.2 M-81,769.2c0.3,0.1-0.2,0.7-0.4,0.4
	C-81.3,769.3-81.2,769.2-81,769.2 M-75.5,769.1c0.5,0.5,0.1-0.2,0.3,0.4C-75.7,769.5-75.7,769.4-75.5,769.1 M-56.9,769
	c-0.2,0.2-0.3,0.1-0.5-0.1C-57.2,768.8-57,768.8-56.9,769 M-64.5,769c-0.2,0.1-0.4,0.1-0.6-0.1C-65.2,768.5-64.4,768.5-64.5,769
	 M-62.3,768.6c-0.2,0.1-0.3,0.2-0.5,0.2C-62.8,768.5-62.7,768.6-62.3,768.6 M-76.9,768.5c0.3-0.1,0.4,0.1,0.4,0.4
	C-76.9,769.1-77.1,769-76.9,768.5 M-82.8,769.4c-0.5,0-0.9-0.3-1.1-0.7C-83.3,768.2-82.8,768.9-82.8,769.4 M-84.7,768.6
	c0.2-0.1,0.4,0,0.5,0.2C-84.3,768.8-84.5,768.7-84.7,768.6 M-81.9,768.4c-0.1,0.2-0.3,0.2-0.5,0.2
	C-82.3,768.3-82.1,768.2-81.9,768.4 M-65.7,768C-66.1,768.5-66,767.9-65.7,768 M-74.4,768c0.4,0,0.5,0.5-0.2,0.5
	C-74.8,768.3-74.7,768.1-74.4,768 M-76.9,768c0.2-0.1,0.3-0.1,0.5,0C-76.6,768.3-76.8,768.3-76.9,768 M-56.3,767.8
	C-55.7,768-56.4,768-56.3,767.8 M-65.3,767.7C-65.2,767.9-65.2,767.9-65.3,767.7z M-66.3,767.7C-65.8,767.9-66.6,768.1-66.3,767.7
	 M-65.8,767.7C-65.4,767.7-65.9,768.1-65.8,767.7 M-62.5,767.6c0.3,0.2-0.3,0.7-0.5,0.6C-63.1,767.6-62.7,767.9-62.5,767.6
	 M-73.6,767.4c0.4,0,0.5,0.1,0.3,0.3C-73.7,768.1-73.5,767.5-73.6,767.4z M-66.3,767.2C-65.7,767.2-66.4,767.7-66.3,767.2
	 M-64.3,767.4c-0.2,0.1-0.3,0.1-0.5,0.2c0.5,0.4,1,0.6,1.6,0.8c0,1.7-0.9-0.2-0.8-0.1c0.3,0-0.9,0-1.1,0c0.1-0.7,0.5,0.1-0.3-0.9
	C-65,767.2-64.6,767.2-64.3,767.4 M-74.1,767.2C-74.1,767.3-73.8,767.2-74.1,767.2 M-82.9,767.2c-0.1,0.2-0.2,0.2-0.4,0.1
	C-83.2,767.2-83,767.1-82.9,767.2 M-62.6,767.1c-0.3,0.3-0.7,0.4-1.2,0.3C-63.5,767.2-63.1,767.1-62.6,767.1 M-73.6,767.1
	c0.2-0.1,0.2,0.3,0,0.2V767.1z M-76.9,767c0.3,0.1,0.5,0.2,0.6,0.5C-76.6,767.6-76.8,767.4-76.9,767 M-74,768c0.3-0.1,0.4,0,0.5,0.3
	C-73.7,768.2-74.3,768-74,768 M-55.8,750.3c0.2-0.1,0.3-0.1,0.5-0.1c0.1,0.1,0.1,0.3,0.1,0.4C-55.4,750.6-55.6,750.5-55.8,750.3
	 M-56.6,750.5c0,0.4-0.7,0.4-0.8,0.2C-57.1,750.4-56.9,750.3-56.6,750.5 M-84.3,751.6c0.5,0.1,0.5,1.1-0.1,1.2
	C-84.4,752.4-84.4,752-84.3,751.6 M-61.2,751.9c0.6,1.2-1,0.8-1.1,0.8C-62,752.4-61.6,752.1-61.2,751.9 M-55.1,752.2
	c0.2,0.3,0.4,0.7,0.5,1.1C-55.3,753.7-55.6,752.5-55.1,752.2 M-62.3,756.7c-0.3-0.2-0.9-0.8-1.1-0.9c0,0.4-0.1,0.8-0.3,1.1
	c-1.3-1-1.4-3.3,0.3-1.8c0.5-0.6,0.5-1.7,0.9-2.4C-61.9,753.7-61.6,755.8-62.3,756.7 M-77.1,752.9c0.4,0.1,1.5,1.5,1.5,1.5
	c0.3,0.4,1.8,1.8,0,2c-0.5-1,0.2-0.7-0.5-0.9c0.2,0-0.3,1-0.7,0.5C-77.4,755.3-77.1,754-77.1,752.9 M-81.6,755.6
	C-81.5,755.3-81.5,756-81.6,755.6 M-76.2,757C-75.8,756.9-76.2,757.6-76.2,757 M-83.6,757.3c0.2,0.1,0.4,0.1,0.6,0.2
	C-83.1,757.9-83.6,757.5-83.6,757.3 M-75.6,757.3C-75.5,757.3-75.4,757.9-75.6,757.3 M-49.9,757.4c0.8-0.3,1.2-0.1,1.2,0.4
	C-49,758.3-49.9,757.9-49.9,757.4 M-63.3,757.6C-62.9,757.6-63.3,758.1-63.3,757.6 M-91.4,757.7c0.4,0.1,0.4,0.3,0.1,0.5
	C-91.4,758.1-91.4,757.9-91.4,757.7 M-73.1,757.8c0.2,0.4,1.3,1.8,0.2,2C-73,759.1-73.5,758.5-73.1,757.8 M-66.5,757.9
	c0.7,0.7,0.6,1.6,0.2,2.4c-0.5,0.1-0.5-0.5-1-0.2C-67.2,759.4-66.8,758.7-66.5,757.9 M-63.8,757.9
	C-63.7,758.1-63.7,758.1-63.8,757.9z M-55.6,757.9c-0.2,0.1-0.4,0.2-0.6,0.3C-56.2,758-55.9,757.9-55.6,757.9 M-48.2,758.3
	C-47.9,758.5-48.4,758.6-48.2,758.3 M-74,759.4c0.4,0.7,0,1.9-0.8,2C-76.1,761.5-74.7,759.6-74,759.4 M-65.3,760
	C-61.5,761.2-66.8,763.2-65.3,760 M-67.7,761.1c0.3,0.7-0.3,1.2-0.5,1.8C-68.6,762.5-67.8,761.5-67.7,761.1 M-78,761.3
	C-77.9,761.1-77.9,761.7-78,761.3 M-75.6,764.2c-0.1-0.2,0.6-1.6,0.8-1.7C-73.7,762-75.1,763.9-75.6,764.2 M-74.1,762.4
	C-74,762.6-74.1,762.7-74.1,762.4 M-65.1,763.1C-65.2,763.4-65.4,762.8-65.1,763.1 M-73.9,763.1C-73.8,763.7-74.7,763-73.9,763.1
	 M-74.6,763.5C-74.5,763.7-74.6,763.8-74.6,763.5 M-65.4,763.7C-65.1,764.1-65.1,764.1-65.4,763.7 M-89.6,763.8
	C-88.8,763.6-89.7,764.3-89.6,763.8 M-76.2,764.4C-75.9,764.3-76.2,765.1-76.2,764.4 M-73.5,764.9c0.2,0,0.4,0,0.6,0
	c-0.2,0.3-0.4,0.4-0.8,0.5C-73.6,765.2-73.6,765-73.5,764.9 M-63.2,765c0.2-0.1,0.2,0.3,0,0.2V765z M-66.4,765.4
	c0.7,0.1,0.5-0.3,0.8,0.5C-65.9,765.9-66.2,765.7-66.4,765.4 M-74.1,765.7c0.2,0.3,0.3,0.5,0.2,0.9c-0.5-0.1-1-0.1-1.6-0.2
	C-75.1,766-74.7,765.7-74.1,765.7 M-65.2,766.2c0.2,0,1.4,0.4,1.3,0.7c-0.5,0.1-1.1,0.1-1.6,0.1C-65.3,766.7-65.2,766.5-65.2,766.2
	 M-76.6,766.5c0.4-0.1,0.9,0,1.2,0.3C-75.9,766.9-76.3,766.8-76.6,766.5 M-74,766.6c0,0.1-0.7,1-0.2,0.9c-0.9,0.4-0.7,0.4-1.1,0.1
	c-0.1,0.3-0.2,0.6-0.3,0.9c-1.5-0.3,1.1-1.4,1.1-1.4c-0.2-0.1-0.3-0.1-0.5-0.2C-74.7,766.6-74.3,766.6-74,766.6 M-72.9,766.6
	C-73.1,767-73.4,766.8-72.9,766.6 M-65,763.4c0.1-0.2,0.2-0.3,0.3-0.5c0.7,0.3,0.7,1.3,1.1,1.8C-64.2,764.6-65.1,764-65,763.4
	 M-73.7,788.3c-0.6,0.1-0.7-1.1-0.9-1.5c-0.7-1.2-1.4-1.5-2.3-2.4c-0.7-0.7-2.4-2.5-2.2-3.5c0-0.1,1.2-1.4,1.3-1.1
	c-0.2,0,0.4,1.3-0.1,1.5c0.6,0.4,0.5,0.8,1.1,1c0.3,0.1,2.1-0.2,2.5-0.1c0.7,0,2,0.5,2.2,0.4c0-0.2-0.1-0.3-0.2-0.5c0.3,0,0.6,0,1,0
	C-71.9,784.2-72.6,786.3-73.7,788.3 M-87.7,764c-0.4-0.2-0.9-0.1-1.3-0.3c-0.5-0.2,0-0.6-0.3-0.9c-0.2,0.1-0.4,0.2-0.7,0.2
	c-0.3-0.4-0.2-1.2,0.2-1.5c1.5-1,0.6,0.1,1.1,0.3c0.8,0.4,2,1.9,2.9,2.5c2.2,1.4,2.5,1.3,2.4,3.5c-0.8,0-2.9,0.6-3.1-0.8
	c1.1-0.1,1-0.1,2.4-0.5c-0.2-0.6-1.7,0.5-2.1,0.2c0-0.2,0.1-0.2,0.3-0.2c-0.4-0.8-1.2-0.4-0.6-1.2c-0.2-0.3-0.5-0.4-0.9-0.4
	c0.5,0.6,0.4,1.3,0.3,1.8c-0.4-0.2-0.7-0.5-1-1c0.2,0,0.3-0.1,0.5-0.2C-87.5,765.7-90.3,764.2-87.7,764 M-54.9,767
	c-0.7,0.3,1.3,0.7,1.2,0.6c-0.1,0.3-0.3,0.6-0.7,0.7c0,0-0.4-0.1-0.5-0.2c0,0.2,0.1,0.2,0.3,0.2c-0.3,0.3-0.7,0.4-1.2,0.2
	c0.2-0.8-0.3-1.3-0.1-2c0.4-1,3-1.9,4-2.6c0.6-0.4,1.6-2.2,2.2-1.9c1.6,0.8-1.1,2.1-1.5,2.5c0.1,0.2,0.3,0.3,0.5,0.4
	c-0.5,0.6-0.9,1.3-1.6,1.7c0.1-0.4,0.3-0.7,0.5-1c-0.3-0.1-0.6,0-0.9,0.3c0.3,1,0.3,0.4-0.6,1.2c0,0.2,0.1,0.2,0.3,0.2
	C-53.4,767.8-54.5,766.9-54.9,767 M-86,761.1c-0.6,1.9,3.8,3.2,4.5,0.9c0.5-1.7-0.8-0.8-2-0.9C-83.9,761.1-85.7,760.1-86,761.1
	 M-80.1,751.6c0.3,1.1-1.7,1.4-2,1.7c-0.3,0.4,0.3,2-0.9,1.9c0.2,0.8,1.2,0.6,1.6,1.1c0.1,0.8-2.6,0.2-2.9-0.3
	c0.3-0.1,0.6-0.1,0.9-0.2c-0.3-0.8-1.6-0.6-2.2-0.5c1-2.4,1.3-0.2,2.8-1.6c0.8-0.6,0-1.4,0.9-2.1s1.3-0.7,2.3-0.4
	c2.9,1,0.3,4.4,0.9,6.5c0.2,0,1.7-1.9,1.4-0.3c0.7-0.1,1.9-0.3,2,0.7c0.5-0.7,0.5-1.6,1.3-1.9c1.7,1.2-1,2.2-1.2,2.5
	c-0.3,0.5-0.2,1.9-0.9,1.6c0-0.1,0.3,5.3-0.6,1.8c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2-0.7,0.6-1.2-0.2-1.4c0.3-0.2,0.3-0.5,0.2-0.9
	c-1.7-0.4-3-0.9-2.6,1.7c0.2,1,0.4,2.3,1,2.9c0,0,1.4,0.8,1.6,0.8c-0.8,1-0.5,1.1-0.7,1.9c-0.1,0.5-0.7,1.2-0.6,1.5
	c0.3,0.1,0.8,0.1,0.9,0.6c-0.2,0.1-0.4,0.3-0.5,0.5c0.2,0.6,1,0.6-0.1,0.9c0.2-0.5,0.1-1.5-0.4-1.8c-0.6,0.3-0.1,1,0.1,1.2
	c-0.2,0.5-1,0.2-1.3,0.1c0.1-0.1,0.2-0.3,0.3-0.4c0.2,0.4-2.2-0.3-1.4-0.2c0.1-0.7,0.6-0.7,0.6-1.3c-1.1-0.2,0.3,0.1,0.2-1.2
	c-0.1-0.6-0.3-1.1-0.6-1.6c-0.3-0.1-0.5,0-0.7,0.1c0.1,0.3,0.1,0.5,0.1,0.8c-1.1-0.2-0.2-1.6-0.5-1.9c-0.6-0.6-1.7-0.6-2.5-1
	c-1.8-0.8-3.1-2.4-3.5-4.5c-0.8,0.4-1.7-0.3-2.4-0.5c0.3,0.4,0.7,0.7,1,1.1c0,0.1-1.6,1-1.6,1.1c-0.3-0.6-0.4-1.2-0.5-1.8
	c1.2,0,0.5-0.9,1.2-1.2c1-0.4,2.5-0.2,3.6-0.5c0,0,1.3-0.7,1.9-0.6c1.2,0.1,2.1,1.5,3.3,0.6c0.8-0.6,0-3.1,0.6-4.2
	C-80.6,753.5-78.8,751.1-80.1,751.6 M-84.3,759.9c0,0.3,0.2,0.3,0.5,0.1C-84,759.6-84,759.9-84.3,759.9 M-59.8,752.2
	c0.2,0.3-0.8-0.7-0.6-0.7c0.4-0.3,0.9-0.5,1.4-0.6c0,0.6,2,2,2.3,2.3c0.6,0.9-0.1,0.9,1.2,1.6c1,0.5,1.1-0.5,2,1.1
	c-0.7,0.3-2.1-0.3-2.4,0.6c0.3,0.1,0.6,0.1,0.9,0.2c-0.4,0.8-2.4,0.8-2.9,0.4c0.3-0.7,1.4-0.4,1.6-1.2c-1.6-0.4-0.3-1.3-1-2.2
	c0,0-2.5-0.5-1.8-1.5C-59.4,752.2-59.5,752.2-59.8,752.2c-0.4,0.6,1,1.6,1.2,2.1c0.3,0.6,0.6,1,0.5,2c-0.1,1.2-1,2,0.5,2.4
	c1,0.3,2.1-0.8,3-0.8c0.5,0,1.8,0.5,1.6,0.4c0.7,0.2,2.3,0.2,2.9,0.5c2,0.9,0.8,1.7,0.9,3.1c-0.7-0.1-0.2-1.1-1.2-0.9
	c0.1-0.1,1-1.2,1-1.2c-0.1-0.6-1.3,0.6-1.5,0.6c-0.3-0.1-0.6-0.1-1-0.2c-0.1,0.6-0.3,1.2-0.5,1.7c-1.1,2.1-3.4,3-5.5,3.6
	c0.3,0.4,0.3,2-0.5,2c-0.5-0.5,0.1-0.9-0.6-0.5c-1.3,0.8-0.1,1.5-0.1,2.6c-0.6-0.2-0.7,1-1.9,1.1c0.1-0.3,0.1-0.6,0-0.9
	c-0.5,0.2-0.2,0.7-0.5,1c-0.4,0,0-0.6-0.6-0.5c-0.1-0.6,1-0.8,1-1.2c-0.2-0.4-0.6-0.2-0.8-0.9c0.1,0.2,0.1-0.9,0.1-0.9
	c0-1-0.5-0.4-0.6-1.2c1.6-0.6,2.2-1.5,2.4-3.4c0.3-2.4-0.5-2.8-2.5-1.9c-0.2,0.2-0.2,1.7,0,2.2c-0.1-0.1-0.3-0.1-0.4-0.2
	c-0.8,1.3-0.7,1.1-0.9-0.3c0-0.2,0.4-0.7,0.4-1.1c0-1.1-0.4,0.1-0.5-0.3c-0.1-0.5,0.1-1.1-0.2-1.5c-0.4-0.5-0.9-0.3-1.4-0.8
	c-0.8-0.8-1.1-1.8-0.3-2.6c0.5,0.7,1.2,1.6,1.5,2.5c0.4-0.3,1.3-1.4,2-0.7c0.7-1.3,0.7-0.3,1.4,0.3C-60,756.7-62.8,752.6-59.8,752.2
	 M-55.5,761.7c-0.7,0-2-0.6-2.7-0.1c0.3,1.9,2.3,2.9,3.9,1.7C-52.6,761.9-54.1,760.2-55.5,761.7 M-55.2,760.7c0.2,0,0.6-0.1,0.3-0.3
	C-55.2,760.2-55.3,760.3-55.2,760.7"
    />
  </svg>
</template>

<script>
export default {
  name: "SvgLogoImage",
  components: {},
};
</script>

<style scoped lang="scss">
.st0 {
  fill: #e6e7e8;
}
.st1 {
  fill: #231f20;
}
</style>
