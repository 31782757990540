<!-- eslint-disable max-len -->
<template>
  <nav
    class="nav flex bg-white shadow-sm fixed top-0 inset-x-0 inset-y-0 z-30 h-16 items-center padding-app"
    :class="{ 'nav--hidden': showingLanding || !pageDataReady }"
  >
    <div class="w-full max-w-screen-xl relative mx-auto">
      <div class="flex items-center">
        <div class="nav__logo mr-3 lg:mr-0 overflow-hidden fade-in">
          <NuxtLink class="flex" to="/">
            <SvgLogoImage></SvgLogoImage>
            <img
              id="logo-header-text"
              :src="imageConstants.logo.header.text"
              class="max-w-none self-center fade-up"
              v-if="grid.lg"
              alt="londolozi logo"
            />
          </NuxtLink>
        </div>
        <div class="nav__menu flex-grow hidden md:flex">
          <ul
            class="flex flex-grow items-center justify-center din text-grey-600 text-lg space-x-3"
          >
            <li
              v-for="(item, index) in menuItems"
              :key="item.route"
              class="fade-up xl:flex"
              :class="item.class ? item.class : ''"
              :style="`--delay: ${((index + 1) * 0.1).toFixed(2)}s;`"
            >
              <global-link v-if="!item.external" :to="item.route" class="p-2">
                {{ item.textShort && grid.lg ? item.textShort : item.text }}
              </global-link>
              <a
                v-if="item.external"
                :href="item.external"
                class="p-2"
                target="_blank"
                rel="noreferrer"
                >{{ item.text }}</a
              >
            </li>
          </ul>
        </div>
        <div
          class="nav__book flex flex-grow md:flex-initial items-center justify-center md:ml-3"
        >
          <ButtonBook class="fade-in" />
        </div>
        <div class="nav__menu-button ml-3 justify-end flex">
          <MenuButton class="fade-in" />
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, computed, watch } from "vue";
import store from "@/store";
import { useMenuItems } from "@/composables/useMenuItems";
import SvgLogoImage from "@/components/Svgs/LogoImage.vue";
import ButtonBook from "@/components/Buttons/ButtonBook.vue";
import MenuButton from "@/components/Buttons/ButtonMenu.vue";
import imageConstants from "@/util/imageConstants";

export default {
  components: {
    ButtonBook,
    SvgLogoImage,
    MenuButton,
  },

  async setup() {
    const route = useRoute();
    const { menuItems } = useMenuItems();

    const showingLanding = computed(() => store.state.modifiers.landing);
    const pageDataReady = computed(() => store.state.modifiers.pageDataReady);

    // Computed
    const scrollElement = computed(() =>
      document.getElementById("app-content")
    );
    const breakpoint = computed(() => grid.breakpoint);

    onMounted(() => {
      if (route.name !== "index___en") {
        store.commit("setLanding", false);
        return;
      }
    });

    watch(breakpoint, (val) => {
      console.log("%c%s", "color:lime;", "breakpoint", val);
    });

    watch(
      () => route.fullPath,
      () => {
        if (route.name !== "index___en") {
          setTimeout(() => {
            store.commit("setLanding", false);
          }, 0);
          return;
        }
        store.commit("setLanding", true);
      }
    );

    return {
      showingLanding,
      menuItems,
      imageConstants,
      pageDataReady,
    };
  },
};
</script>

<style scoped lang="scss">
.nav {
  transform: translate3d(0, 0, 0);
  transition: 0.15s transform ease-in;
  @include min-width;
  &__menu {
    li {
      animation-delay: var(--delay);
      a:hover {
        color: var(--blue);
      }
    }
  }
  &.nav--hidden {
    box-shadow: none;
    transform: translate3d(0, -100%, 0);
    transition: 0.15s transform ease-out;
  }
  &__logo,
  &__menu-button .btn-menu {
    width: 40px;
    height: 40px;
    svg {
      width: 40px;
      height: 40px;
    }
  }
  .router-link-exact-active {
    color: black;
  }
  .booking-button {
    z-index: 1;
  }
  &__logo {
    #logo-header-text {
      width: 110px;
      margin-bottom: 11px;
      margin-left: 14px;
    }
  }
}

@screen xs {
  .nav {
    &__logo,
    &__menu-button .btn-menu {
      width: 44px;
      height: 44px;
      svg {
        width: 44px;
        height: 44px;
      }
    }
  }
}
@screen lg {
  .nav {
    &__menu-button {
      width: 44px;
    }
    &__logo {
      width: auto;
    }
  }
}
</style>
