<!-- eslint-disable max-len -->
<template>
  <!-- Google Tag Manager (noscript) -->
  <noscript
    ><iframe
      src="https://www.googletagmanager.com/ns.html?id=GTM-WNMV6H"
      height="0"
      width="0"
      style="display: none; visibility: hidden;"
    ></iframe
  ></noscript>
  <!-- End Google Tag Manager (noscript) -->
  <UnsupportedBrowser v-if="isIe" />
  <div
    v-if="!isIe && !pending"
    class="app w-full h-full relative left-0"
    :class="[
      { 'app--perspective': appPerspective },
      { 'app--drawer-transitioning': drawerTransitioning },
      `app--${orientation}`,
      { 'app--touch': touch },
      { 'app--no-touch': !touch },
      { 'app--submitting-booking': submittingBooking },
      { 'app--gallery-open': galleryOpen },
      drawerOpenClass,
      deviceClasses,
    ]"
  >
    <div
      class="app__container z-20 relative w-full min-h-full"
      :class="[{ 'app__container--transformed': drawerOpened }]"
    >
      <div
        id="app-content"
        ref="scrollElement"
        class="app__content absolute top-0 left-0 h-full w-full"
      >
        <LondoHeader />
        <slot />
        <div
          id="card-sizer"
          class="card invisible absolute top-0"
          style="z-index: -10;"
        ></div>
      </div>
      <transition name="fade">
        <div
          v-if="drawerOpened"
          class="overlay-app inset-0 absolute z-30 fade"
          ref="overlayApp"
          @click="toggleDrawerState"
        ></div>
      </transition>
    </div>
    <transition name="fade">
      <Drawer class="app__drawer" v-show="drawerOpened">
        <transition name="fade">
          <BookingEnquiry v-if="showBooking" />
        </transition>
        <transition name="fade">
          <LondoNav v-if="!showBooking && drawerOpened" />
        </transition>
      </Drawer>
    </transition>
    <PrivacyConsentBanner />
  </div>
</template>

<script lang="ts">
import smoothscroll from "smoothscroll-polyfill";
import { ref, computed, watch } from "vue";
import store from "@/store";
import LondoHeader from "@/components/TheHeader.vue";
import LondoNav from "@/components/TheNav.vue";
import Drawer from "@/components/Drawer.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import BookingEnquiry from "@/components/Enquiries/BookingEnquiry.vue";
import PrivacyConsentBanner from "@/components/PrivacyConsentBanner.vue";
import UnsupportedBrowser from "@/components/Utilities/UnsupportedBrowser.vue";
import { useDevice } from "@/composables/useDevice";
import { CONSTANTS } from "@/util/constants";

export default {
  name: "LondoLayout",
  components: {
    LondoHeader,
    LondoNav,
    Drawer,
    LoadingIndicator,
    BookingEnquiry,
    PrivacyConsentBanner,
    UnsupportedBrowser,
  },
  async setup() {
    const route = useRoute();
    const { device } = useDevice();

    const touch = ref(false);
    const orientation = ref(null);

    onMounted(() => {
      touch.value = screen.touch;
      orientation.value = screen.orientation;

      //watch for gallery opening and closing
      const body = document.body;
      const galleryObserver = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (
            mutation.type === "attributes" &&
            mutation.attributeName === "class"
          ) {
            let open = body.classList.contains("lg-on");

            if (galleryOpen.value !== open) {
              console.log(
                "%c%s",
                "color: IndianRed;",
                "toggleModifier galleryOpen",
                open
              );
              store.commit("toggleModifier", {
                key: "galleryOpen",
                val: open,
              });
            }
          }
        });
      });
      galleryObserver.observe(body, { attributes: true });
    });

    const { data, pending, error } = await useFetch("/api/data");
    if (data && data.value && process.client) {
      if (data.value.outline) store.dispatch("setOutline", data.value.outline);
      if (data.value.campRates)
        store.commit("setCampRates", data.value.campRates);
      if (data.value.footer) store.commit("setFooter", data.value.footer);
    }

    const appPerspective = ref(false);
    const drawerOpened = computed(() => store.state.modifiers.drawerOpen);
    const drawerTransitioning = computed(
      () => store.state.modifiers.drawerTransitioning
    );
    const showBooking = computed(() => store.state.modifiers.showBooking);
    const submittingBooking = computed(
      () => store.state.modifiers.submittingBooking
    );
    const isIe = computed(() => {
      if (typeof window !== "undefined") {
        const ua = navigator.userAgent;
        const isIe = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

        return isIe;
      }
    });
    const drawerOpenClass = computed(() => {
      const drawerOpenClass = drawerOpened.value
        ? "app--drawer-open"
        : "app--drawer-closed";
      return showBooking.value
        ? `${drawerOpenClass} app--booking-open`
        : drawerOpenClass;
    });
    const deviceClasses = computed(() => {
      if (!device) {
        return;
      }
      return Object.keys(device)
        .filter((key) => device[key] === true)
        .join(" ");
    });

    const galleryOpen = computed(() => store.state.modifiers.galleryOpen);

    const toggleDrawerState = () => store.commit("toggleDrawerState");
    const openDrawerBooking = () => store.commit("openDrawerBooking");
    watch(screen, (_) => {
      orientation.value = screen.orientation;
      touch.value = screen.touch;
      console.log(
        "%c%s",
        "color: pink;",
        "screen change, orientation:",
        orientation.value
      );
    });

    watch(
      route,
      (newRoute, _) => {
        const { hash } = newRoute;
        if (hash && hash === `#${CONSTANTS.bookingHash}`) {
          if (!drawerOpened.value) {
            setTimeout(() => openDrawerBooking(), 0);
          }
        }
      },
      { immediate: true }
    );

    watch(drawerOpened, (val) => {
      if (val) {
        appPerspective.value = true;
      } else {
        setTimeout(() => {
          appPerspective.value = false;
        }, 400);
      }
    });

    return {
      appPerspective,
      drawerOpened,
      drawerTransitioning,
      drawerOpenClass,
      deviceClasses,
      showBooking,
      toggleDrawerState,
      openDrawerBooking,
      submittingBooking,
      isIe,
      pending,
      touch,
      orientation,
      galleryOpen,
    };
  },
};
</script>

<style scoped lang="scss">
.app {
  background: white;
  &__container {
    transition: transform 0.4s;
    transform: translateZ(0) translateX(0) rotateY(0deg);
    .overlay-app {
      background: rgba(0, 0, 0, 0.1);
    }
    &--transformed {
      transform: translateZ(0) translateX(-54.5%) rotateY(70deg);
      box-shadow: 20px 12px 50px 0px rgba(0, 0, 0, 0.1);
    }
  }
  &__content {
    -webkit-overflow-scrolling: touch;
    overflow: auto;
    overflow-x: hidden;
    -webkit-backface-visibility: hidden;
  }
  &--perspective {
    perspective: 1500px;
    @apply fixed;
    z-index: 1;
    .app__container {
      @apply cursor-pointer overflow-hidden absolute h-full w-full;
      backface-visibility: hidden;
      .app__content {
      }
    }
  }
  &--submitting-booking {
    .app__container {
      @apply cursor-default;
    }
  }
}

@screen xs {
  .app {
    &__container {
      &--transformed {
        transform: translateZ(0px) translateX(-50%) rotateY(70deg);
      }
    }
  }
}
@screen sm {
  .app {
    &__container {
      &--transformed {
        transform: translateZ(0px) translateX(-40%) rotateY(65deg);
      }
    }
  }
}
@screen md {
  .app {
    &__container {
      &--transformed {
        transform: translateZ(0px) translateX(-42%) rotateY(55deg);
      }
    }
  }
}
@screen lg {
  .app {
    &__container {
      &--transformed {
        transform: translateZ(0px) translateX(-25%) rotateY(50deg);
      }
    }
  }
}
@screen xl {
  .app {
    &__container {
      &--transformed {
        transform: translateZ(0px) translateX(-18%) rotateY(50deg);
      }
    }
  }
}
@screen xxl {
  .app {
    &__container {
      &--transformed {
        transform: translateZ(0px) translateX(-12.7%) rotateY(50deg);
      }
    }
  }
}
@screen xxxl {
  .app {
    &__container {
      &--transformed {
        transform: translateZ(0px) translateX(-11%) rotateY(40deg);
      }
    }
  }
}
</style>
